.input-phone-number {
  margin-top: 10px;

  input {
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 44px;
  }
}
